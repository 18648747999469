import React from "react"
import SEO from "../components/seo"
import Text from "atomic-core/components/patterns/01-atoms/texts/text/Text"
import { Link } from "gatsby"
import { Translations } from "../utils/translations"
const NotFoundPage = () => (
  <div className="pt-60 pr-70 pb-60 pl-70">
    <SEO title="404: Not found" />
    <h1>{Translations.PageNotFoundPage.title}</h1>
    <Text globalModifiers="text-size-medium font-secondary-bold mt-10">
      {Translations.PageNotFoundPage.description}
      <Link className="link text-underline" to="/help">
          Help Page
      </Link>
      .
    </Text>
  </div>
)

export default NotFoundPage
